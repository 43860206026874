import { gql } from 'graphql-tag';

export default gql`
    fragment AvatarFragment on Avatar {
        altText
        height
        id
        mimeType
        url
        width
    }
`;
